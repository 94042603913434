<template>
  <div :is="layout">
    <v-container fluid>
      <v-data-table
          :server-items-length="list_count"
          :headers="headers"
          :items="desserts"
          :items-per-page="10"
          :loading-text="$t('data_loading')"
          :loading="table_loading"
          @pagination="changePagination"
          class="elevation-1"

          :footer-props="{
          showFirstLastPage: true,
        }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              {{
                $t(
                    module_type === 0
                        ? "airport.airportEdge"
                        : "airport.securityNode"
                )
              }}</v-toolbar-title
            >
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-toolbar>
        </template>
        <template v-slot:item.is_two_way="{item}">
          {{item.is_two_way===1 ? $t('airport.yes') : $t('airport.no')}}
        </template>
        <!-- <template v-slot:[`item.remote_stand`]="{ item }">
          <div v-if="item.remote_stand == '1'">是</div>
          <div v-if="item.remote_stand == '0'">否</div>
        </template> -->
        <template v-slot:[`item.Action`]="{ item }">
          <v-btn @click="edit_open(item)" small color="accent" text
          >{{$t('edit')}}
          </v-btn>
          <v-btn @click="del(item)" small color="accent" text>{{$t('delect')}}</v-btn>
        </template>
      </v-data-table>
    </v-container>
    <v-btn @click="add_open" color="accent" fab class="float-btn">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="800px">
      <v-card>
        <v-card-title>{{
            $t(edit_mode ? "airport.edit_edge" : "airport.add_edge")
          }}</v-card-title>
        <v-container class="pd-30">
          <v-row>
            <v-col :cols="6">
              <v-text-field  :label="$t('airport.start_id')" v-model="info.start_id">

              </v-text-field>
            </v-col>
            <v-col :cols="6">
              <v-text-field :label="$t('airport.end_id')" v-model="info.end_id">

              </v-text-field>
            </v-col>
            <v-col :cols="6">
              <v-select :label="$t('airport.is_two_way')" :items="ways" v-model="info.is_two_way">

              </v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="submit" color="accent">{{ $t("confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {get_list, add_info, edit_info, del_info} from "../../../api/airportEdge";

export default {
  name: "index",
  data() {
    return {
      layout: null,
      desserts: [],
      search_info: {
        pageindex: 1,
        pagesize: 10,
        group:"",
        module_type: 0,
      },
      table_loading: false,
      list_count: 0,
      module_type: null,
      dialog:false,
      edit_mode: false,
      info:{},
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("airport.start_id"),
          align: "center",
          value: "start_id",
          sortable: false,
        },
        {
          text: this.$t("airport.end_id"),
          align: "center",
          value: "end_id",
          sortable: false,
        },
        {
          text: this.$t("airport.is_two_way"),
          align: "center",
          value: "is_two_way",
          sortable: false,
        },
        {
          text: this.$t("airport.Action"),
          align: "center",
          value: "Action",
          sortable: false,
        },
      ]
    },
    ways(){
      return [
        {
          text:this.$t('airport.no'),value:0
        },
        {
          text:this.$t('airport.yes'),value: 1
        }
      ]
    }
  },
  mounted() {
    this.layout = this.$route.meta.use_layout
    this.search_info.airport_id = this.$route.params?.id ;
    this.module_type=this.$route.params?.module_type * 1
    this.search_info.module_type=this.$route.params?.module_type * 1
  },
  methods:{
    changePagination(data) {
      this.search_info.pageindex = data.page;
      this.search_info.pagesize = data.itemsPerPage;
      // this.doGet();
      this.minusPage(this.list_count)
    },
    minusPage(data){
      let totalPage = Math.ceil((data) / this.search_info.pagesize);
      let pageindex =this.search_info.pageindex > totalPage? totalPage: this.search_info.pageindex;
      this.search_info.pageindex = pageindex < 1 ? 1 : pageindex;
      this.doGet();
    },
    doGet(pageindex = this.search_info.pageindex) {
      this.search_info.pageindex = pageindex;
      this.table_loading = true;
      const closeLoading = this.$loading()
      if(this.module_type===1){
        // get_support_vehicle_all_path_data(this.search_info)
        //     .then((res) => {
        //       this.desserts = res.results;
        //       this.list_count = res.count;
        //       //   if (Math.ceil(res.count / this.search_info.pagesize)< this.search_info.pageindex -1) {
        //       //   this.search_info.pageindex--
        //       // }
        //     })
        //     .finally(() => {
        //       this.table_loading = false;
        //     });
      }
      else if (this.module_type === 0) {
        get_list(this.search_info)
            .then((res) => {
              this.desserts = res.results;
              this.list_count = res.count;
            })
            .finally(() => {
              this.table_loading = false;
              closeLoading()
            });
      }
    },
    edit_open(item){
      // console.log(item)
      this.info=JSON.parse(JSON.stringify(item))
      this.edit_mode = true;
      this.dialog=true
    },
    del(item){
      if (this.module_type === 0) {
        this.$confirm({
          title: this.$t('confirm_del'),
          content: this.$t('airport.delete_edge'),
          okText: this.$t('confirm'),
          cancelText: this.$t('cancel')
        }).then((dialog) => {
          del_info(item.id).then((res) => {
            //  this.doGet();
            this.minusPage(this.list_count-1)
          });
        });
      }
    },
    add_open(){
      this.edit_mode = false;
      this.info={}
      this.dialog=true
    },
    submit(){
      if (this.edit_mode){
        if (this.module_type === 0) {
          edit_info(this.info).then(res=>{
            this.$toast({
              title: this.$t('success'),
              message: this.$t('airport.edit_success_edge'),
            });
            this.dialog = false;
          }).finally(() => {
            this.doGet();
          });
        }
      }else {
        if (this.module_type === 0) {
          add_info(this.info)
              .then(res => {
                this.$toast({
                  title: this.$t('success'),
                  message: this.$t('airport.add_success_edge'),
                });
                this.dialog = false;
              })
              .finally(() => {
                this.doGet();
              });
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/css/global.styl';
</style>
